.service-dialog{
  .MuiPaper-root{
    width:370px;
    background-color:#fff;
    border-radius: 16px 16px 0px 0px;
    .service-main{
      padding: 48px 24px 72px 24px;
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      >div{
        width:100%;
      }
      .service-main_head{
        display:flex;
        align-items: center;
        justify-content: space-between;
        .MuiAvatar-root{
          width:98px;
          height:98px;
        }
        .main-head_info{
          text-align: right;
          .info-item{
            padding:5px 0;
            display:flex;
            align-items: center;
            border-bottom:1px solid #D8C2BB;
            .info-item_label{
              color:#370E00;
              font-size:12px;
              letter-spacing: 0.5px;
            }
            .info-item_number{
              color:#370E00;
              font-size:24px;
              font-weight: 500;
            }
          }
          .info-item:last-child{
            border:0;
          }
          .MuiRating-root{
            margin-top:5px;
          }
        }
      }
      .service-main_name{
        font-size: 24px;
        font-weight:500;
      }
      .service-main_date{
        margin:0 auto;
        width:156px;
        height:211px; 
        position:relative;
        .service-main_date-text{
          font-size:18px;
          width:100%;
          text-align: center;
          letter-spacing: 0.018px;
          position:absolute;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
        }
        .service-main_date-active{
          width:100%;
          transition: height .1s;
          position: absolute;
          left: 0;
          // top: 0;
          bottom:0;
          overflow: hidden;
          img{
            width:100%;
            height:211px; 
          }
        }
      }
      .service-main_btn{
        display: flex;
        gap:8px;
        .MuiButtonBase-root{
          flex:2;
          height:40px;
          width:100%;
          border-radius: 8px;
        }
        .mian-btn_Pause{
          flex:1;
        }
      }
    }
  }
}