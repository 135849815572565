.personal-dialog{
  .MuiPaper-root{
    height:90vh;
    width:100%;
    background-color:var(--primary-background);
    border-radius: 25px 25px 0px 0px;
    .personal-main{
      padding:15px;
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      flex-direction: column;
      .personal-main_top{
        width:108px;
        height:4px;
        border-radius: 12px;
        background:#fff;
        margin:0 auto;
      }
      .personal-main_close{
        display:flex;
        justify-content: right;
      }
      .personal-main_card{
        padding:15px 20px;
        background-color:#fff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        display:flex;
        align-items: center;
        gap:35px;
        .card-left{
          text-align: center;
          .MuiAvatar-root{
            width:98px;
            height:98px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          }
          .card-name{
            margin-top:5px;
            font-size: 24px;
            font-weight: 500;
          }
        }
        .card-right{
          width:100%;
          .card-list{
            display:flex;
            gap:5px;
            flex-wrap: wrap;
            .card-list_item:last-child{
              border:0;
            }
            .card-list_item{
              width:100%;
              border-bottom:1px solid #D8C2BB;
              color:#370E00;
              padding:10px 0;
              .item-type{
                font-size:15px;
                font-weight: 700;
                letter-spacing: 0.3px;
              }
              .item-text{
                font-size: 20px;
                font-weight: 500;
              }
              .item-des{
                margin-top:5px;
                font-size:12px;
                letter-spacing: 0.5px;
              }
            }
          }
        }
      }
      .personal-main_info{
        display:flex;
        flex-wrap: wrap;
        gap:11px;
        .info-item{
          width:100%;
          display:flex;
          align-items: center;
          justify-content: space-between;
          gap:16px;
          .info-item_label{
            display:flex;
            gap:16px;
            align-items: center;
          }
          .info-item_copy{
            font-size:12px;
            font-weight: 600;
            letter-spacing: 0.5px;
            color:var(--primary-color);
          }
        }
      }
      .personal-main_intro{
        color:#370E00;
        font-size:14px;
        font-weight: 300;
        letter-spacing: 0.056px;
      }
      .personal-mian_read{
        text-align: center;
        font-size:12px;
        font-weight: 600;
        letter-spacing: 0.5px;
      }
      .personal-mian_title{
        display:flex;
        align-items: center;
        gap:10px;
        font-size:14px;
        font-weight: 600;
      }
      .personal-mian_img{
        display:flex;
        flex-wrap: wrap;
        gap:16px;
        img{
          width:64px;
          height:64px;
          border-radius: 4px;
          object-fit:cover;
        }
      }
      .personal-main_btn{
        display:flex;
        gap:16px;
        justify-content: space-between;
        .MuiButtonBase-root{
          width:100%;
          height:40px;
          border-radius: 8px;
        }
      }
    }
  }
}