.code{
  padding:76px 0;
  text-align: center;
  .code-title{
    color: var(--WC-sys-light-on-primary-fixed, #370E00);
    font-size: 36px;
    font-weight: 600;
  }
  .code-text{
    margin-top:25px;
    font-size: 18px;
    letter-spacing: 0.018px;
  }
  .code-input{
    position:relative;
    .code-input_hidden{
      position: absolute;
      left:0;
      top:0;
      right:0;
      bottom:0;
      z-index:2;
      opacity:0;
    }
    .code-input_list{
      margin-top:71px;
      padding:0 38px;
      display:flex;
      justify-content: space-between;
      .ipt-item{
        width:65px;
        height:65px;
        color:#333;
        font-size:20px;
        background: var(--primary-background, #FFEDE7);
        display:flex;
        align-items: center;
        justify-content: center;
      }
      .ipt-item_active::after{
        content:'';
        width:1px;
        height:30px;
        background-color:#333;
        animation: flicker 0.5s linear infinite alternate;
      }
    }
  }
}
@keyframes flicker {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}