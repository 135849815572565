.register{
  padding-top:var(--primary-top);
  .register-back{
    padding:30px 20px;
    background-color:#fff;
    span{
      display:flex;
      gap:15px;
      align-items: center;
      cursor: pointer;
      font-size:18px;
      letter-spacing: 0.5px;
    }
  }
  .register-logo{
    padding:10px 36px;
    text-align: center;
  }
  .register-form{
    padding:26px 59px;
    form{
      display:flex;
      flex-wrap: wrap;
      gap:24px;
      .MuiFormControl-root{
        width:100%;
      }
      .register-form_checkbox{
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .color0075b4{
          color:#0075b4;
        }
        .MuiFormControlLabel-root{
          .PrivateSwitchBase-root-5{
            padding:0 9px;
          }
          .MuiTypography-body1{
            font-size: 12px;
            letter-spacing: 0.5px;
          }
          .MuiButtonBase-root{
            color:var(--primary-color);
          }
        }
        .register-form_checkbox-forgot{
          color:var(--primary-color);
          font-size: 12px;
          letter-spacing: 0.5px;
        }
      }
      .MuiButton-containedPrimary{
        width:100%;
        height:40px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.1px;
        background-color: var(--primary-color);
      }
      .register-form_login{
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: var(--WC-sys-light-on-surface-variant, #53433E);
        letter-spacing: 0.4px;
      }
    }
  }
}