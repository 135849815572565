.scanCode{
  display:flex;
  flex-direction: column;
  height:100vh;
  position:relative;
  .top-head{
    background:rgba(255,255,255,0.3)
  }
  .scan-code_btn{
    width:100%;
    position:absolute;
    bottom:0;
    padding:0 70px;
    display:flex;
    justify-content: space-between;
    margin-bottom:50px;
    box-sizing: border-box ;
    svg{
      path{
        fill: var(--primary-color);
      }
      circle{
        fill:var(--primary-background)
      }
    }
    svg:nth-child(2){
      path{
        fill: var(--primary-color);
        stroke:none;
      }
    }
  }
}
#qrCanvas,#icon-banner-file,#icon-image-file{
  display:none;
}