.collect{
  .collect-tap{
    padding:0 20px;
    margin-top:20px;
    display:flex;
    justify-content: right;
    align-items: center;
    gap:10px;
    .collect-tap_active{
      svg{
        path,rect{
          stroke:var(--primary-color) ;
        }
        
      }
    }
  }
  .collect-main{
    padding:20px;
    .MuiPaper-root{
      width:100%;
    }
    .list-container{
      display:flex;
      flex-direction: column;
      gap:20px;
      .list-container_item{
        padding:8px 16px;
        background-color:var(--primary-background);
        display:flex;
        gap:20px;
        .MuiAvatar-root{
          border-radius: 5px;
        }
        .item-info{
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          flex-direction: column;
          .item-info_name{
            font-size:16px;
            font-weight: 300;
            letter-spacing: 0.25px;
            color:#53433e;
          }
          .item-info_date{
            font-size:15px;
            color:#201a18;
            font-weight: 300;
            letter-spacing: 0.5px;
          }
        }
      }
      .MuiPaper-root{
        width:100%!important;
        max-width:100%!important;
      }
    }
  }
}