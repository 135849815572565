.clause{
  .main{
    padding:20px;
    letter-spacing: 2px;
    word-break:break-all;
    .title:nth-child(1){
      margin-top:0px;
    }
    .title{
      font-size:16px;
      font-weight: 600;
      margin-top:30px;
    }
    .name{
      font-weight: 600;
    }
    .text{
      font-size:15px;
      margin-top:10px;
    }
    ul{
      margin-top:10px;
      margin-left:30px;
      li{
        margin-top:5px;
        list-style-type: decimal;
      }
    }
    .date{
      font-size:15px;
      margin-top:20px;
    }
  }
}