.aptitudeadd{
  display:flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap:10px;
  .MuiFormControl-root{
    width:100%;
  }
  .aptitudeadd-upload{
    width:100%;
    height:80vw;
    background-color:var(--primary-background);
    display:flex;
    align-items: center;
    justify-content: center;
    border:1px solid var(--primary-color);
    border-radius: 5px;
    img{
      width:100%;
      height:100%;
      object-fit:scale-down;
    }
    .aptitudeadd-upload_empty{
      display:flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      gap:11px;
      svg{
        rect{
          fill:var(--primary-color)
        }
        path{
          fill:#fff;
        }
      }
    }
  }
  .MuiButtonBase-root{
    height:50px;
    font-size:16px;
  }
}