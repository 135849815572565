.qrCode{
  .qr-code_main{
    width:100%;
    height:100%;
    padding:60px;
    box-sizing: border-box;
    canvas{
      width:100%!important;
      height:100%!important;
    }
  }
  .qr-code_btn{
    padding:0 70px;
    display:flex;
    justify-content: space-between;
    margin-bottom:50px;
    svg{
      path{
        fill: var(--primary-color);
      }
      circle{
        fill:var(--primary-background)
      }
    }
    svg:nth-child(2){
      path,line{
        fill: none;
        stroke:var(--primary-color);
      }
      path:last-child{
        fill: var(--primary-color);
        stroke:none;
      }
    }
  }
}

#qrCanvas,#icon-banner-file{
  display:none;
}

