.sand-payment{
  min-height:100vh;
  display: flex;
  flex-direction: column;
  .sand-payment_main{
    flex: 1 ;
    display: flex;
    flex-direction: column;
    .MuiTabs-root{
      .MuiTabs-scroller{
        .MuiTabs-flexContainer{
          justify-content: space-around;
          .MuiButtonBase-root{
            color:#370E00;
            font-size:14px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .sand-payment_content{
      flex:1;
      display: flex;
      flex-direction: column;
      .scan-code{
        flex:1;
        display: flex;
        flex-direction: column;
        .scan-code_title{
          margin-top:60px;
          text-align: center;
          span{
            color:#370E00;
            font-size:14px;
            letter-spacing: 0.5px;
            border-bottom:2px solid var(--primary-color);
            padding:12px 10px;
          }
        }
        .bcid{
          margin-top:30px;
        }
      }
      .collection{
        .collection_title{
          margin-top:22px;
          text-align: center;
          span{
            color:#370E00;
            font-size:14px;
            letter-spacing: 0.5px;
            border-bottom:2px solid var(--primary-color);
            padding:12px 10px;
          }
        }
        .collection_code{
          margin-top:10px;
          padding:30px 80px;
          box-sizing: border-box;
        }
        .collection_main{
          padding:40px;
          padding-top:0;
          .collection_main-container{
            display:flex;
            align-items: center;
            justify-content: space-between;
            .collection_main-title{
              width:30%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .collection_main-right{
              display:flex;
              align-items: center;
              gap:20px;
              .collection_main-right-num{
                display:flex;
                align-items: center;
                gap:15px;
                .container_amount{
                  font-size:16px;
                  color:#000;
                  letter-spacing: 0.1px;
                }
              }
              .collection_main-right-price{
                color:var(--primary-color);
                font-size:24px;
                font-weight: 500;
                span{
                  margin-left:5px;
                  font-size:14px;
                  font-weight: 400;
                  letter-spacing: 0.5px;;
                }
              }
            }
          }
          .MuiDivider-root{
            margin:18px 0;
            background: #D8C2BB;
          }
          .collection_main-statistics{
            display:flex;
            justify-content: space-between;
            color:var(--primary-color);
            font-size:24px;
            font-weight: 500;
            span{
              font-size:14px;
              font-weight: 400;
            }
          }
          
        }
      }
      .collection_main-btn{
        margin-top:16px;
        .MuiButtonBase-root{
          width:100%;
          height:40px;
          border-radius: 8px;
          font-weight: 600;
          font-size:16px;
        }
      }
      .online-transaction{
        margin-top:6px;
        padding:16px;
        .cart-content-item{
          padding:16px;
          box-sizing: border-box;
          display:flex;
          align-items: center;
          gap:10px;
          justify-content: space-between;
          position:relative;
          .content-item_close{
            position:absolute;
            right:16px;
            top:16px;
            cursor: pointer;
          }
          .content-item_left{
            width:100%;
            display:flex;
            min-width: 0;
            .content-item_left-img{
              margin-right:10px;
              width:96px;
              min-width:96px;
              height:96px;
              border-radius: 16px;
              overflow:hidden;
              img{
                width:100%;
                height:100%;
                object-fit:cover;
              }
            }
            .content-item_left-info{
              width:100%;
              min-width: 0;
              display:flex;
              flex-wrap: wrap;
              flex-direction: column;
              justify-content:space-around;
              .item-info-title{
                width:100%;
                color: var(--WC-ref-neutral-neutral20, #362F2C);
                font-size:20px;
                letter-spacing: 0.15px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              .item-info-container{
                display:flex;
                align-items: center;
                justify-content: space-between;
                .item-info-container_num{
                  display:flex;
                  align-items: center;
                  justify-content: space-between;
                }
                .container_amount{
                  font-size:16px;
                  color:#000;
                  letter-spacing: 0.1px;
                }
                .content-item_right-money{
                  font-size: 22px;
                  white-space: nowrap;
                  .right-money_unit{
                    font-size: 12px;
                    letter-spacing: 0.5px;
                  }
                }
              }
              
            }
          }
        }
        .cart-compute{
          margin:24px 0;
          .cart-compute_statistics,.cart-compute_Remaining{
            display:flex;
            align-items: center;
            justify-content: space-between;
            font-size: 24px;
            .cart-compute_statistics-money{
              .statistics-money_unit{
                margin-left:5px;
                font-size:14px;
                letter-spacing: 0.5px;
              }
            }
          }
          .cart-compute_statistics{
            color: var(--primary-color, #CE4C09);
          }
          .MuiDivider-root{
            margin:18px 0;
            background: #D8C2BB;
          }
        }
        .cart-button{
          margin-top:24px;
          display:flex;
          gap:8px;
          .MuiButton-root{
            width:100%;
            height:40px;
            border-radius: 8px;
            font-size:16px;
            font-weight: 600;
            .MuiButton-label{
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      .Mui-disabled{
        background:none!important;
      }
    }
  }
}