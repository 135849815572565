.marketplace{
  min-height:100vh;
  background: #F4F4F4;
  padding-bottom:20px;
  .marketplace-head{
    padding:10px 17px;
    padding-top:var(--primary-top);
    background: #F4F4F4;
    position: sticky;
    top:0;
    z-index: 997;
    .marketplace-head_top{
      display:flex;
      justify-content:space-between;
      align-items: center;
      .tourist-head_right{
        display:flex;
        gap:6px;
        .logout{
          text-align: center;
          .logout-img{
            width:48px;
            height:48px;
            display:flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: #E6E6E6;
            cursor: pointer;
          }
          span{
            color:#370e00;
            font-size:12px;
            font-weight: 500;
          }
        }
      }
    }
    .MuiTabs-root{
      margin-top:10px;
      padding:10px 0;
      width:100%;
      box-sizing: border-box;
      .MuiTabs-flexContainer{
        border-bottom:1px solid #6A6A6A;
        .MuiButtonBase-root {
          min-height:30px;
          width:50%;
          color:#6A6A6A;
          font-size:18px;
          letter-spacing: 0.1px;
          padding:5px;
        }
        .Mui-selected{
          color:var(--primary-color);
        }
      }
      .MuiTabs-indicator{
        height:2px;
      }
    }
    .marketplace-head_status{
      color:#252525;
      font-size:15px;
      letter-spacing: 0.1px;
      display:flex;
      justify-content:space-between;
      align-items: center;
      .svg-active{
        path{
          stroke:var(--primary-color)
        }
      }
      svg{
        path{
          stroke:#6A6A6A
        }
      }
    }
  }
  .marketplace-main{
    
  }
  .tourist{
    .tourist-head{
      display:flex;
      justify-content:space-between;
      align-items: center;
      .tourist-head_right{
        display:flex;
        gap:6px;
      }
    }
    .tourist-city{
      padding:0 17px;
      margin-top:12px;
      display:flex;
      align-items: center;
      .tourist-city_text{
        margin-left:8px;
        font-size: 22px;
        font-weight: 500;
      }
    }
    
  }
  .card-list{
    width:100%;
    display:flex;
    gap:16px;
    overflow-x: auto;
    box-sizing: border-box;
  }
  .user{
    .card-list{
      
    }
  }
  .home-all{
    display:flex;
    flex-wrap: wrap;
    flex-direction: column;
    width:100%;
    .home-all_title{
      margin-top:16px;
      padding:0 17px;
      display:flex;
      align-items: center;
      color: #333;
      font-size: 22px;
      font-weight: 500;
      .home-all_title-img{
        width:40px;
        text-align: center;
        img{
          height:40px;
        }
      }
    }
    .community-a{
      .card{
        background-color:#FDEEE7 !important;
        .MuiButtonBase-root{
          background-color:#F16526;
        }
      }
    }
    .community-b{
      .card{
        background-color:#FFE4EC !important;
        .MuiButtonBase-root{
          background-color:#A71D6B;
        }
      }
    }
    .home-all_main{
      order: 1;
      width:100vw;
    }
    .home-all_mian-a{
      order: 1;
    }
    // .home-all_mian-b{
    //   order: 3;
    // }
    .home-all_mian-c{
      order: 3;
    }
    .community-c{
      .card{
        background-color:#E6FFDC !important;
        .MuiButtonBase-root{
          background-color:#056E00;
        }
      }
    }
  }
}