.MuiFormControl-root{
  .MuiFormLabel-root{
    color:#53433E;
  }
  .MuiInputBase-root{
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color:#53433E;
  }
} 
#icon-button-file,#icon-button-shoot{
  display:none;
}
.MuiButton-containedPrimary{
  width:100%;
  font-size:16px!important;
  letter-spacing: 0.1px!important;
  font-weight: 600!important;
  height:40px;
}

button{
  text-transform:none!important;
}

.MuiSnackbar-root{
  z-index: 99999!important;
  top:50%!important;
  margin-top:-25px;
}

a{
  text-decoration:none;
  color: black;
}

.control-phone_cpde{
  max-width:80px;
  .MuiInputBase-root{
    .MuiSelect-select{
      padding:0;
    }
    .MuiOutlinedInput-notchedOutline{
      border:none;
    }
    
  }
}

.MuiPaper-root{
  .MuiList-root{
    padding:0;
    .select-head{
      height:41px;
      line-height: 41px;
      display:flex;
      align-self: center;
      justify-content: center;
      background-color:var(--primary-color);
      color:#fff;
      font-size:14px;
      border-radius: 4px 4px 0 0;
      letter-spacing: 0.5px;
      position:relative;
      .select-head_back{
        width:10px;
        position: absolute;
        left:20px;
        top:12px;
      }
    }
    .MuiButtonBase-root{
      height:41px;
      font-size:14px;
      letter-spacing: 0.5px;
      border-bottom:1px solid #F6F6F6;
    }
    .MuiButtonBase-root:last-child{
      border:0;
    }
  }
}

.MuiAvatar-root{
  img{
    width:100%;
    height:100%;
    object-fit:cover;
  }
}
.MuiDrawer-root,.MuiDialog-root{
  z-index: 9999!important;
}
.css-p1oacb-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  color:#fff!important;
  background-color:#B1B1B1!important;
}

.css-124z6ey-MuiFormLabel-root-MuiInputLabel-root.Mui-error{
  color: #53433E!important;
}
.css-jfeki0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: #53433E!important;
}
