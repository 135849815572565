.sharing-details{
  padding:0px 14px;
  box-sizing: border-box;
  .drawer-main{
    overflow: auto;
    // padding:16px;
    box-sizing: border-box;
    display:flex;
    flex-wrap: wrap;
    gap:16px;
    color:#370E00;
    >div{
      width:100%;
    }
    .preview-head{
      position:relative;
      .preview-head_avatar{
        text-align: center;
        .MuiAvatar-root {
          margin:0 auto;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          width:70px;
          height:70px;
        }
        .avatar-name{
          margin-top:12px;
          font-size: 18px;
          font-weight: 400;
        }
      }
      .preview-head_close{
        position: absolute;
        right:0;
        top:0;
      }
    }
    .preview-title{
      font-size:24px;
      font-weight: 00;
      text-align: center;
    }
    .preview-tag{
      display:flex;
      gap:11px;
      height:24px;
      justify-content: center;
      align-items: center;
      span{
        font-size:18px;
        letter-spacing: 0.1px;
      }
      .MuiDivider-flexItem{
        max-width:1px;
        width:1px;
        height:27px;
        background-color:#370E00;
      }
    }
    .preview-user{
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      .preview-user_item{
        width:100%;
        padding:8px 16px;
        background:#FFEDE7;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .preview-user_item-left{
          display:flex;
          gap:16px;
          align-items: center;
          .item-left_info{
            .item-left_info-name{
              color:#53433E;
              font-size:14px;
              letter-spacing: 0.5px;
            }
            .item-left_info-des{
              color:#201A18;
              font-size:12px;
              font-weight: 600;
              letter-spacing: 0.5px;
            }
          }
        }
        .preview-user_item-right{
          font-size:12px;
          letter-spacing: 0.5px;
        }
      }
    }
    .preview-content{
      text-align: center;
      font-size:14px;
      letter-spacing: 0.056px;
      font-weight: 300;
      border-bottom: 1px solid #85736D;
      padding-bottom:10px;
    }
    .preview-img{
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      img{
        width:100%;
        border-radius: 8px;
      }
    }
    .preview-list{
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      .list-card{
        width:100%;
        display:flex;
        gap:8px;
        height:50px;
        align-items: center;
        border-bottom:1px solid #85736D;
        .list-card_label{
          width:100px;
          font-size:12px;
          letter-spacing: 0.5px;
          color:#53433E;
        }
        .list-card_value{
          font-size:14px;
          font-weight: 300;
          letter-spacing: 0.056px;
          color:#53433E;
        }
      }
    }
    .preview-button{
      display:flex;
      gap:16px;
      .MuiButtonBase-root{
        width:100%;
        height:50px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1px;
        border-radius: 8px;
      }
    }
    
  }
  
}
.sharing-details_btn{
  width:100%;
  position:fixed;
  bottom:0;
  padding:12px 16px;
  background-color:#fff;
  display: flex;
  gap:8px;
  box-sizing: border-box;
  .button-icon{
    width:50px!important;
    min-width:50px;
    border-radius: 50%!important;
    box-sizing: border-box;
    padding:8px;
  }
  .MuiButtonBase-root{
    width:100%;
    height:50px;
    font-size:16px;
    font-weight: 600;
    border-radius: 8px;
    letter-spacing: 0.1px;
  }
  .button-flex-2{
    width:100%;
  }
}
