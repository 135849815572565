.transfer{
  .transfer-main{
    margin-top:37px;
    padding:30px;
    .transfer-main_item{
      margin-bottom:46px;
      .transfer-title{  
        font-size:14px;
        letter-spacing: 0.5px;
        color:#53433e;
      }
      .transfer-card{
        margin-top:10px;
        border:1px solid var(--primary-color);
        background-color:var(--primary-background);
        border-radius: 5px;
        padding:11px 16px;
        display:flex;
        align-items: center;
        gap:13px;
        color:#53433E;
        .transfer-card_info{
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          .transfer-card_info-name{
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.5px;
          }
          .transfer-card_info-address{
            margin-top:8px;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.5px;
          }
        }
        .transfer-card_right{
          display:flex;
          align-items: center;
          margin-left:auto;
          svg{
            path,line{
              stroke:var(--primary-color);
            }
          }
        }
      }
    }
  }
}