.my {
  padding: 16px;
  padding-top: var(--primary-top);

  .my-avatar {
    display: flex;
    justify-content: center;

    .MuiAvatar-root {
      width: 98px;
      height: 98px;
      border: 2px solid var(--primary-border);
    }
  }

  .my-address {
    text-align: center;
    padding: 0 40px;
    margin: 10px 0;
    font-size: 14px;
    color: #333;
  }

  .my-id {
    margin-bottom: 12px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);

    svg {

      path,
      circle {
        fill: var(--primary-color);
      }

    }
  }

  .my-account {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    font-size: 18px;
    letter-spacing: 0.018px;

    .my-account_name {
      margin-right: 10px;
    }

    .MuiDivider-flexItem {
      width: 1.5px;
      margin-top: 13px;
      height: 20px;
      background-color: var(--primary-color);
    }

    .my-account_hm {
      display: flex;
      align-items: center;

      .my-account_hm-price {
        text-decoration: underline;
      }

      svg {
        padding: 10px;

        path {
          fill: var(--primary-color);
        }
      }
    }
  }

  .my-icon {
    display: flex;
    gap: 8px;
    justify-content: center;

    .my-icon_item {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-background);
      border-radius: 50%;

      svg {

        // fill:var(--primary-color);
        path {
          fill: var(--primary-color);
        }
      }
    }

    .my-icon_item:nth-child(2),
    .my-icon_item:nth-child(3) {

      path,
      line {
        fill: none;
        stroke: var(--primary-color);
      }
    }
  }

  .my-family {
    margin-top: 24px;

    .my-family_title {
      color: #000;
      font-size: 14px;
      letter-spacing: 0.056px;
    }

    .my-family_main {
      margin-top: 13px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      .my-family_mian_add,
      .MuiAvatar-root {
        width: 40px;
        height: 40px;
        margin-right: -13px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      }

      .my-family_mian_add {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 50%;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .MuiTabs-root {
    margin-top: 24px;

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        width: 100%;
        background: var(--primary-background);

        .MuiButtonBase-root {
          max-width: 50%;
          width: 50%;
          font-size: 14px;
          letter-spacing: 0.5px;
          color: #201A18;
        }

      }
    }
  }

  .my-content {
    margin-top: 24px;

    .personal-details {
      .personal-details_title {
        color: #000;
        font-size: 18px;
        letter-spacing: 0.1px;
      }

      .personal-details_address {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        align-items: center;

        svg {

          path,
          circle {
            fill: var(--primary-color);
          }
        }
      }

      .personal-details_main {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 0 8px;

        .mian-card {
          width: 100%;
          padding: 13.5px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #D8C2BB;

          .mian-card_info {
            display: flex;
            align-items: center;
            color: #000;

            .mian-card_info-label {
              width: 72px;
              font-size: 12px;
              letter-spacing: 0.5px;
              margin-right: 8px;
            }

            .mian-card_info-value {
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0.056px;
            }
          }
        }

        .mian-card:last-child {
          border: 0;
        }
      }

      .personal-details_text {
        color: #000;
        font-size: 14px;
        letter-spacing: 0.056px;
        margin-top: 40px;
      }
    }

    .settings {
      .MuiFormGroup-root {
        width: 100%;
        flex-wrap: nowrap;

        .MuiFormControlLabel-root {
          width: 50%;
          margin: 0;
          justify-content: center;

          .MuiTypography-root {
            display: flex;
            align-items: center;
            color: #000;
            font-size: 14px;
            letter-spacing: 0.4px;

            img {
              margin-right: 5px;
            }
          }
        }
      }

      .settings-title {
        margin-top: 24px;
        color: #000;
        font-size: 18px;
        letter-spacing: 0.018px;
      }

      .settings-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        form {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .MuiFormControl-root {
          width: 100%;
        }

        .settings-form-btn {
          margin-top: 16px;
          display: flex;
          gap: 16px;

          .MuiButtonBase-root {
            width: 100%;
            height: 40px;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.1px;
            border-radius: 8px;
          }
        }
      }

      .settings-main {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 0 8px;

        .mian-card {
          width: 100%;
          height: 49px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #D8C2BB;

          .mian-card_info {
            display: flex;
            align-items: center;
            color: #000;

            .mian-card_info-label {
              width: 72px;
              font-size: 12px;
              letter-spacing: 0.5px;
              margin-right: 8px;
            }

            .mian-card_info-value {
              font-size: 14px;
              font-weight: 300;
              letter-spacing: 0.056px;
            }
          }
        }

        .mian-card:last-child {
          border: 0;
        }
      }
    }
  }
}
.delete_dialog {
  .MuiPaper-root{
    width: 358px;
    border-radius: 10px;
    .apply-for_dialog-top{
      height:16px;
      background-color: var(--primary-color);
    }
    .MuiDialogContent-root{
      padding:30px 20px;
      .MuiTypography-root{
        display:flex;
        align-items: center;
        .MuiFormControlLabel-root{
          margin-right:0;
        }
        >span{
          color:#370e00;
          font-size:16px;
          font-weight: 300;
          letter-spacing: 0.25px;
        }
      }
    }
    .MuiDialogActions-root{
      padding:0;
      border-top:1px solid #FFE4EC;
      display:flex;
      text-align: center;
      justify-content:space-between;
      .MuiButtonBase-root{
        width:100%;
        height:52px;
        box-sizing: border-box;
      }
      .MuiButtonBase-root:nth-child(1){
        border-right:1px solid #FFE4EC;
      }
    }
  }

}