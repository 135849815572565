.payment-status{
  padding-top:var(--primary-top);
  display:flex;
  align-items: center;
  justify-content: center;
  min-height:100vh;
  background-color: var(--primary-background);
  position:relative;
  .payment-status_close{
    position: absolute;
    right:16px;
    top:calc(var(--primary-top) + 16px);
  }
  .payment-status_main{
    text-align: center;
    color:#370E00;
    display:flex;
    gap:16px;
    flex-wrap: wrap;
    flex-direction: column;
    .payment-status_main-title{
      font-size:24px;
      font-weight: 500;
    }
    .payment-status_main-price,.payment-status_main-des{
      font-size:18px;
      font-weight: 400;
    }
  }
}