.food{
  padding:16px;
  padding-top:calc(var(--primary-top) + 20px);
  background-color: #F2F2F2;
  min-height:90vh;
  .food-head{
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
    .food-head-back{
      position:absolute;
      left:0;
    }
    .food-head-content{
      display:flex;
      gap:8px;
      align-items: center;
      .food-head_title{
        color:#000;
        font-size: 24px;
        font-weight: 500;
      }
      .food-head_status{
        height:22px;
        box-sizing: border-box;
        padding:4px;
        border-radius: 16px;
        background:#009700;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -1px;
        color:#fff;
      }
    }
    
  }
  .food-main{
    margin-top:16px;
    display:flex;
    flex-wrap: wrap;
    gap:20px 16px;
    .food-main_item{
      padding:8px;
      border-radius: 8px;
      background: #FFF;
      display:flex;
      align-items: center;
      gap:16px;
      color:#012200;
      font-size: 22px;
      font-weight: 500;
    }
  }
}