.top-head{
  padding:var(--primary-top) 16px;
  padding-bottom:0;
  width:100vw;
  height:90px;
  box-sizing: border-box;
  display:flex;
  justify-content: space-between;
  align-items: center;
  position:sticky;
  top:0;
  background:#fff;
  z-index: 9998;
  .top-head_left{
    position:absolute;
    left:20px;
  }
  .top-head_title{
    width:100%;
    text-align: center;
    color:#311300;
    font-size:24px;
    font-weight: 500;
    margin-left:12px;
  }
  .top-head_right{
    position:absolute;
    right:20px;
  }
}