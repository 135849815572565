.WeightInput{
  height:64px;
  padding:16px;
  box-sizing: border-box;
  background:#fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  flex-direction:row;
  .MuiInputBase-root{
    height:100%;
    padding:0 10px;
    box-sizing: border-box;
  }
  .MuiInputBase-input,.MuiTypography-root{
    color:#012200;
    text-align: center;
    font-size:22px;
    font-weight: 500;
  }
  .MuiInputBase-root::before{
    content:'';
    border:0!important;
  }
  .MuiInput-underline:after{
    border:0!important;
  }
}