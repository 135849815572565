.entrance{
  padding:26px 48px;
  .entrance-logo{
    padding:47px 36px;
    text-align: center;
  }
  .entrance-info{
    text-align: center;
    .entrance-info_text{
      font-size: 14px;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 0.056px;
    }
    .entrance-info_item{
      margin:13px 0;
      display:flex;
      flex-wrap: wrap;
      gap:16px;
      .item-card{
        width:100%;
        padding:16px 24px 16px 12px;
        display:flex;
        align-items: center;
        border-radius: 8px;
        background: #FFEDE7;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        img{
          margin-right:23px;
        }
        span{
          font-size:18px;
          font-weight: 350;
          letter-spacing: 0.5px;
        }
      }
      .item-card3{
        background: var(--LMC-sys-light-primary-container, #FFD9E5);
      }
      .item-card2{
        background: var(--LMC-sys-light-primary-container, #CBFFB8);
      }
    }
    .entrance-info_entrance{
      font-size: 12px;
      letter-spacing: 0.5px;
    }
    .entrance-info_login{
      font-size:14px;
      color:#53433e;
      letter-spacing: 0.4px;
    }
  }
}