.shear-card{
  margin-top:20px;
  width:100%;
  padding-bottom:24px;
  box-sizing: border-box;
  border-radius: 16px;
  background: var(--WC-sys-light-on-primary, #FFF);
  overflow:hidden;
  .card-main{
    position:relative;
    .shear-card_tips{
      color:#FFF;
      width:100%;
      position: absolute;
      top:0;
      border-radius:16px 16px 0 0;
      padding:10px;
      text-align: center;
      background-color:#FFBF0B;
      font-size:12px;
      font-weight: 600;
      letter-spacing: 0.5px;
      box-sizing: border-box;
    }
    .shear-card_mistake{
      background-color:#F16526;
    }
    .shear-card_applicant{
      background-color:#056E00;
    }
    .shear-card_img{
      width:100%;
      height:178px;
      overflow: hidden;
      img{
        width:100%;
      }
    }
    .shear-card_info{
      margin-top:24px;
      padding:16px;
      display:flex;
      flex-wrap: wrap;
      gap:8px;
      >div{
        width:100%;
      }
      .info-title{
        color:#311300;
        font-size:18px;
        text-align: center;
        letter-spacing: 0.1px;
      }
      .info-tag{
        display:flex;
        gap:24px;
        justify-content: center;
        span{
          font-size:14px;
          letter-spacing: 0.1px;
        }
        .MuiDivider-root{
          background-color:#370E00;
        }
      }
      .info-intro{
        letter-spacing: 0.4px;
        color:#370E00;
        font-size:14px;
        font-weight: 350;
        .info-intro_dec{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        span{
          color:var(--primary-color);
          margin-left:5px;
        }
      }
    }
  }
  .card-button{
    padding:0 16px;
    display: flex;
    justify-self: center;
    flex-wrap: wrap;
    gap:20px;
    .card-button_flex{
      width:100%;
      display:flex;
      gap:10px;
      .card-button_cancel{
        color:#fff!important;
        background-color:#B1B1B1!important;
      }
    }
    .MuiButton-containedPrimary{
      width:100%;
      height:40px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.1px;
    }
  }
}