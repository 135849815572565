.initiate-for_dialog{
  .MuiPaper-root{
    max-width: 358px;
    border-radius: 10px;
    .apply-for_dialog-top{
      height:16px;
      background-color: var(--primary-color);
    }
    .MuiDialogContent-root{
      padding:30px 20px;
      .MuiTypography-root{
        display:flex;
        align-items: center;
        .MuiFormControlLabel-root{
          margin-right:0;
        }
        >span{
          color:#370e00;
          font-size:16px;
          font-weight: 300;
          letter-spacing: 0.25px;
        }
      }
    }
    .MuiDialogActions-root{
      padding:0;
      border-top:1px solid #FFE4EC;
      display:flex;
      text-align: center;
      justify-content:space-between;
      .MuiButtonBase-root{
        width:100%;
        height:52px;
        box-sizing: border-box;
      }
      .MuiButtonBase-root:nth-child(1){
        border-right:1px solid #FFE4EC;
      }
    }
  }
  
}
