.card{
  box-shadow:none!important;
  min-width:276px;
  max-width:276px;
  height:auto;
  box-sizing: border-box;
  background-color: var(--primary-card-background)!important;
  border-radius: 16px!important;
  overflow: hidden;
  .card-backdrop{
    height:178px;
    position:relative;
    .MuiAvatar-root{
      width:100%;
      height:100%;
      border-radius: 0;
    }
    .card-backdrop_banner{
      width:100%;
      height:100%;
    }
    .card-backdrop_like{
      position:absolute;
      top:16px;
      right:16px;
      cursor: pointer;
    }
  }
  .card-info{
    height:173px;
    padding: 24px 16px 16px 16px;
    box-sizing: border-box;
    text-align: center;
    .MuiAvatar-circular{
      margin-top:-50px;
      width:48px;
      height:48px;
    }
    .card-info_text{
      .card-info_text-title{
        font-size: 20px;
        letter-spacing: 0.15px;
      }
      .card-info_text-tag{
        color:#370E00;
        margin:8px 0;
        display:flex;
        gap:8px;
        justify-content:space-between;
        font-size: 14px;
        letter-spacing: 0.5px;
        .MuiDivider-root{
          background:#370E00
        }
      }
      .card-info_text-date{
        color:#311300;
        font-size: 12px;
        letter-spacing: 0.5px;
      }
      .MuiButtonBase-root{
        margin-top:7.5px;
        height:40px;
        width:100%;
        font-size:16px;
        border-radius:8px ;
        .MuiButton-label{
          display:flex;
          gap:6px;
        }
      }
    }
  }
}