.sharing-form{
  .sharing-form_main{
    padding:16px;
    .form-upload{
      height:218px;
      display:flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background-color:var(--primary-background);
      border-radius: 16px;
      overflow:hidden;
      .form-uiload_img{
        position:relative;
        img{
          width:100%;
          height:100%;
          object-fit:scale-down;
        }
        svg{
          width:25px;
          height:25px;
          position:absolute;
          right:10px;
          top:10px;
          cursor: pointer;
        }
      }
      .form-uiload_text{
        .upload-text_title{
          display:flex;
          align-items: center;
          justify-content: center;
          gap:8px;
          font-size: 16px;
          font-weight: 600;
          color:var(--primary-color);
        }
        .upload-text_des{
          margin-top:10px;
          padding:0 44px;
          text-align: center;
          color: rgba(0, 0, 0, 0.68);
          font-size:14px;
          letter-spacing: 0.056px;
          font-weight: 300;
        }
      }
    }
    .form-list{
      margin-top:8px;
      display:flex;
      gap:8px;
      flex-wrap: wrap;
      .list-item{
        // overflow:hidden;
        position:relative;
        img{
          width:100%;
          height:100%;
          object-fit:scale-down;
        }
        svg{
          width:20px;
          height:20px;
          top:-5px;
          right:-5px;
          position:absolute;
        }
      }
      .list-item,.list-add{
        width: 56px;
        height: 56px;
        border-radius: 8px;;
        background-color:var(--primary-background);
      }
      .list-add{
        display:flex;
        align-items: center;
        justify-content: center;
        img{
          width:24px;
        }
      }
    }
    .form-picker{
      width:100%;
    }
    .form-input{
      margin-top:24px;
      display:flex;
      flex-wrap: wrap;
      gap:24px;
      >div{
        width:100%;
      }
      .MuiTextField-root{
        width:100%;
      }
      .form-input_flex{
        width:100%;
        display:flex;
        gap:8px;
        .form-input_flex_2{
          width:180%;
        }
        .textfield-select{
          width:100%;
        }
      }
      .date-list{
        margin-top:-5px;
        display:flex;
        gap:16px;
        align-items: center;
        .date-list_left{
          display:flex;
          flex-wrap: wrap;
          gap:24px;
          >div{
            width:100%;
          }
        }
        .date-list_delete{
          svg{
            width:30px;
          }
        }
      }
    }
    .request-form_add{
      width:100%;
      .request-form_add-title{
        color:#85736d;
        font-size:13px;
        margin-bottom:5px;
      }
      .request-form_add-div{
        cursor: pointer;
        width:100%;
        height:56px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color:var(--primary-background);
        svg{
          path{
            fill: var(--primary-color);
          }
        }
      }
    }
    
    .form-button{
      margin-top:30px;
      display:flex;
      gap:16px;
      .MuiButtonBase-root{
        width:100%;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1px;
        border-radius: 8px;
      }
    }
  }
  
}