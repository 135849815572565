.preview-drawer{
  height:100vh;
  z-index: 9999!important;
  .MuiPaper-root{
    width:100%;
    height:auto;
    .drawer-main{
      height:100vh;
      overflow: auto;
      padding:16px;
      padding-top:var(--primary-top);
      box-sizing: border-box;
      display:flex;
      flex-wrap: wrap;
      gap:16px;
      color:#370E00;
      >div{
        width:100%;
      }
      .preview-head{
        position:relative;
        .preview-head_avatar{
          text-align: center;
          .MuiAvatar-root {
            margin:0 auto;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            width:98px;
            height:98px;
          }
          .avatar-name{
            margin-top:5px;
            font-size: 24px;
            font-weight: 500;
          }
        }
        .preview-head_close{
          position: absolute;
          right:0;
          top:0;
        }
      }
      .preview-title{
        font-size:33px;
        font-weight: 600;
        text-align: center;
      }
      .preview-tag{
        width:100%;
        display:flex;
        gap:24px;
        justify-content:center;
        align-items: center;
        span{
          font-size:18px;
          letter-spacing: 0.1px;
        }
        .MuiDivider-root{
          height:27px;
          background-color:#370E00;
          align-self:center;
        }
      }
      .preview-content{
        font-size:14px;
        letter-spacing: 0.056px;
        font-weight: 300;
      }
      .preview-img{
        display:flex;
        gap:16px;
        flex-wrap: wrap;
        img{
          width:100%;
          border-radius: 8px;
        }
      }
      .preview-list{
        display:flex;
        gap:16px;
        flex-wrap: wrap;
        .list-card{
          width:100%;
          display:flex;
          gap:8px;
          height:50px;
          align-items: center;
          border-bottom:1px solid #85736D;
          .list-card_label{
            width:100px;
            font-size:12px;
            letter-spacing: 0.5px;
            color:#53433E;
          }
          .list-card_value{
            font-size:14px;
            font-weight: 300;
            letter-spacing: 0.056px;
            color:#53433E;
          }
        }
      }
      .preview-button{
        display:flex;
        gap:16px;
        .MuiButtonBase-root{
          width:100%;
          height:50px;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.1px;
          border-radius: 8px;
        }
      }
    }
    
  }
}