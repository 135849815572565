.data-list{
  width:100%;
  overflow-x: auto;
  .data-list_main{ 
    display:flex;
    margin:auto;
    gap:8px;
    overflow-x: auto;
    margin-top:16px;
    padding-left:16px;
    padding-right:16px;
  }
}