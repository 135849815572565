.applicant{
  .applicant-main{
    padding:23px;
    padding-bottom:80px;
    .MuiTabs-root{
      .MuiTabs-scroller{
        .MuiTabs-flexContainer{
          justify-content: space-around;
          .MuiButtonBase-root{
            color:#370E00;
            font-size:14px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .applicant-main_list{
      margin-top:33px;
      .MuiFormControl-root{
        width:100%;
        margin:0;
        .MuiFormGroup-root{
          display:flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap:15px;
        }
      }
      .applicant-main_list-agree{
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap:15px;
      }
      .main-list_card{
        height:72px;
        display: flex;
        gap:24px;
        align-items: center;
        padding: 8px 24px 8px 16px;
        box-sizing: border-box;
        background-color:var(--primary-background);
        border-radius: 5px;
        .main-list_card-info{
          .main-list_card-info-name{
            color:#53433E;
            font-size:14px;
            font-weight: 700;
            letter-spacing: 0.5px;
          }
          .main-list_card-info-des{
            margin-top:5px;
            color:#201A18;
            font-size:15px;
            font-weight: 300;
            letter-spacing: 0.5px;
          }
        }
        .MuiFormControlLabel-root,.main-list_card-btn{
          margin-left: auto;
          margin-right:0;
        }
        .main-list_card-btn{
          display:flex;
          align-items: center;
          gap:15px;
          >div{
            text-align: center;
            span{
              margin-top:5px;
              display:block;
              font-size:12px;
              letter-spacing: 0.5px;
            }
            svg{
              path{
                stroke:var(--primary-color);
              }
            }
          }
        }
      }
    }
  }
  .applicant-btn{
    width:100%;
    background:#fff;
    padding:16px;
    box-sizing: border-box;
    position:fixed;
    bottom:0;
    display:flex;
    gap:8px;
    .MuiButtonBase-root{
      height:50px;
      width:100%;
      font-size:16px;
      font-weight: 600;
      letter-spacing: 0.1px;
      border-radius: 6px;
    }
  }
}