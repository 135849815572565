.scan-code_md{
  width:205px;
  height:205px;
  position: relative;
  margin:50px auto;
  .border_corner{
    z-index: 500;
    position: absolute;
    width: 76px;
    height: 76px;
    background: rgba(0,0,0,0);
    border: 5px solid var(--primary-color);
  }
  .border_corner_line{
    position:absolute;
    left: 50%;
    top:50%;
    margin-top:-2.5px;
    margin-left:-150px;
    height:5px;
    background-color:var(--primary-color);
    width:300px;
    border-radius: 5px;
  }
  .border_corner_left_top{
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 30px;
  }
  .border_corner_right_top{
      top: 0;
      right: 0;
      border-left: none;
      border-bottom: none;
      border-top-right-radius: 30px;
  }
  .border_corner_left_bottom{
      bottom: 0;
      left: 0;
      border-right: none;
      border-top: none;
      border-bottom-left-radius: 30px;
  }
  .border_corner_right_bottom{
      bottom: 0;
      right: 0;
      border-left: none;
      border-top: none;
      border-bottom-right-radius: 30px;
  }
}
#bcid {
	width: 100%;
  height:100%;
	text-align: center;
  // display:flex;
  flex:1;
  .tip {
    color: #FFFFFF;
    font-weight: bold;
    text-shadow: 0px -1px #103E5C;
  }
}
