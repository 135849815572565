.transfer-dialog{
  .MuiPaper-root{
    border-radius: 10px;
    .transfer-top{
      height:16px;
      background-color: var(--primary-color);
    }
    .transfer-title{
      margin-top:10px;
      font-size:20px;
      text-align: center;
    }
    .transfer-main{
      padding:10px 20px;
      margin-top:20px;
      display:flex;
      flex-wrap: wrap;
      gap:20px;
      .MuiFormControl-root{
        width:100%;
      }
      .MuiButtonBase-root{
        width:100%;
        border-radius: 8px;
        height:40px;
        font-size:16px;
        font-weight: 600;
      }
    }
  }
  .MuiFormLabel-root{
    background-color:#fff;
    padding:0 5px;
  }
}