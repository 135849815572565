.cart{
  padding:16px;
  padding-top:var(--primary-top);
  .cart-head{
    display:flex;
    justify-content: space-between;
    .cart-head_left{
      display:flex;
      align-items: center;
      cursor: pointer;
      .head-left_text{
        margin-left:8px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1px;
      }
    }
    .cart-head_right{
      color: var(--primary-color, #CE4C09);
      font-size:24px;
      .head-right_money{
        font-weight:600;
        margin:0 5px 0 8px;
      }
      .head-right_unit{
        font-size:14px;
        letter-spacing: 0.5px;
      }
    }
  }
  .cart-content{
    margin-top:24px;
    .cart-content_RadioGroup{
      width:100%;
      display:flex;
      gap:0px;
      align-items: center;
    }
    .cart-content-item{
      width:100%;
      padding:16px;
      display:flex;
      align-items: center;
      gap:10px;
      justify-content: space-between;
      position:relative;
      .content-item_close{
        position:absolute;
        right:16px;
        top:16px;
        cursor: pointer;
      }
      .content-item_left{
        display:flex;
        .content-item_left-img{
          margin-right:10px;
          width:96px;
          height:96px;
        }
        .content-item_left-info{
          .item-info-title{
            width:100%;
            color: var(--WC-ref-neutral-neutral20, #362F2C);
            font-size:20px;
            letter-spacing: 0.15px;
          }
          .item-info-container{
            display:flex;
            align-items: center;
            justify-content: space-between;
            .container_amount{
              font-size:16px;
              color:#000;
              letter-spacing: 0.1px;
            }
          }
        }
      }
      .content-item_right{
        .content-item_right-money{
          font-size: 22px;
          white-space: nowrap;
          .right-money_unit{
            font-size: 12px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .vegetable-card{
      width:100%;
      margin:10px 0;
      padding:8px;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      background:#fff;
      border-radius: 4px;
      .vegetable-card_title{
        font-size:22px;
        font-weight: 500;
        color:#012200;
      }
      .vegetable-card_main{
        margin-top:8px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .vegetable-card_main-input{
          display:flex;
          gap:8px;
          .MuiFormControl-root{
            width:150px;
            height:48px;
            padding:8px;
            box-sizing: border-box;
            background:#fff;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            flex-direction:row;
            .MuiInputBase-input,.MuiTypography-root{
              color:#012200;
              text-align: center;
              font-size:22px;
              font-weight: 500;
            }
            .MuiInputBase-root::before{
              content:'';
              border:0;
            }
            .MuiInput-underline:after{
              border:0;
            }
          }
        }
        .vegetable-card_main-money{
          font-size:28px;
          font-weight: 500;
          span{
            font-size:12px;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin-left:5px;
          }
        }
      }
    }
  }
  .cart-compute{
    margin:24px 0;
    .cart-compute_statistics,.cart-compute_Remaining{
      display:flex;
      align-items: center;
      justify-content: space-between;
      font-size: 24px;
      .cart-compute_statistics-money{
        .statistics-money_unit{
          margin-left:5px;
          font-size:14px;
          letter-spacing: 0.5px;
        }
      }
    }
    .cart-compute_statistics{
      color: var(--primary-color, #CE4C09);
    }
    .MuiDivider-root{
      margin:18px 0;
      background: #D8C2BB;
    }
  }
  .cart-button{
    display:flex;
    gap:8px;
    .MuiButton-root{
      width:100%;
      height:40px;
      border-radius: 8px;
      .MuiButton-label{
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

.way-contact{
  .MuiPaper-root{
    width:304px;
    height:auto;
    padding: 48px 24px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #FFF;
    .way-contact-list{
      display:flex;
      flex-wrap: wrap;
      gap:24px;
      .list-item{
        width:100%;
        display:flex;
        padding:16px;
        align-items: center;
        border-radius: 8px;
        background: var(--primary-background);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        .list-item_img{
          margin-right:16px;
        }
        .list-item_text{
          color: var(--WC-black, #000);
          .list-item_text-title{
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.1px;
          } 
          .list-item_text-des{
            font-size:14px;
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
}