.sharingmark{
  padding:0 61px;
  text-align: center;
  padding-top:var(--primary-top);
  .sharingmark-img{
    margin-top:44px;
    text-align: center;
  }
  .sharingmark-title{
    margin-top:15.5px;
    font-size:28px;
    font-weight: 500;
  }
  .sharingmark-des{
    margin-top:21px;
    font-size:18px;
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  .MuiRating-root{
    margin-top:16px;
    .MuiRating-decimal{
      font-size:40px;
    }
  }
  .sharingmark-btn{
    margin-top:16px;
    .MuiButtonBase-root{
      height:40px;
      border-radius: 8px;
      width:100%;
      font-size:16px;
      font-weight: 600;
      letter-spacing: 0.1px;
    }
  }
}
