.login{
  padding:26px 59px;
  .login-logo{
    padding:73px 36px;
    text-align: center;
  }
  .login-form{
    form{
      display:flex;
      flex-wrap: wrap;
      gap:15px;
      .MuiFormControl-root{
        margin-top:9px;
        width:100%;
      }
      .MuiFormControl-root:nth-child(1){
        margin-top:0;
      }
      .login-form_checkbox{
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .color0075b4{
          color:#0075b4;
        }
        .MuiFormControlLabel-root{
          .PrivateSwitchBase-root-5{
            padding:0 9px;
          }
          .MuiTypography-body1{
            font-size: 12px;
            letter-spacing: 0.5px;
            white-space: nowrap;
          }
        }
        .login-form_checkbox-forgot{
          color:#F0560F;
          font-size: 12px;
          letter-spacing: 0.5px;
        }
      }
    }
    .MuiButtonBase-root{
      width:100%;
      height:40px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.1px;
    }
    .MuiDivider-root{
      padding:26px 0;
      .MuiDivider-wrapper{
        font-size:14px;
        font-weight: 350;
        letter-spacing: 0.4px;
        padding:0 20px;
      }
    }
    .css-1puturl-MuiDivider-root::before, .css-1puturl-MuiDivider-root::after{
      border-color:#000;
    }
    .login-img{
      margin-top:55px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      
      .icon{
        .icon-title{
          font-size:12px;
          letter-spacing: 0.5px;
          margin-bottom:10px;
        }
      }
      .icon:nth-child(2){
        .icon-title{
          margin-bottom:15px;
        }
      }
    }
  }
}