.shop{
  padding:20px 16px;
  .shop-title{
    font-size: 24px;
    color: var(--WC-black, #000);
  }
  .MuiTabs-root{
    margin-top:8px;
    .MuiTabs-scroller {
      .MuiButtonBase-root{
        font-size:14px;
        font-weight: 400;
        letter-spacing: 0.5px;
      }
      .Mui-selected{
        font-weight: 600;
      }
      .MuiTabs-indicator{
        height:3px;
        border-radius: 5px 5px 0 0;
      }
    }
  }
  .shop-product{
    margin-top:10px;
    display:flex;
    flex-wrap: wrap;
    gap:16px;
    .product-item{
      width:100%;
      padding:16px;
      box-sizing: border-box;
      display:flex;
      align-items: center;
      justify-content: space-between;
      .product-item_left{
        width:70%;
        display:flex;
        align-items: center;
        gap:10px;
        .item-left_img{
          border-radius: 4px;
          width:96px;
          min-width:96px;
          height:96px;
          overflow: hidden;
          img{
            width:100%;
            height:100%;
          }
        }
        .item-left_info{
          width:60%;
          color: var(--WC-sys-light-on-primary-container, #370E00);
          .info-date,.info-price{
            margin-top:10px;
          }
          .info-title{
            font-size: 20px;
            letter-spacing: 0.15px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          .info-date{
            font-size: 12px;
            letter-spacing: 0.5px;
          } 
          .info-price{
            display:flex;
            .info-price_money{
              font-size: 22px;
            }
            .info-price_unit{
              margin-top:8px;
              margin-left:5px;
              font-size: 12px;
              letter-spacing: 0.5px;
            }
          }
        }
      }
      .product-item_right{
        .MuiButton-root{
          min-width:46px;
          width:46px;
          height:46px;
          border-radius: 50%;
        }
      }
    }
  }
}