*{
  padding:0;
  margin:0;
  font-family: Noto Sans HK;
}
@font-face {
  font-family: Noto Sans HK;
  src: url("./assets/tff/NotoSansSC-6.ttf");
}
:root{
  --primary-color:#F16526;
  --primary-background:#FFEDE7;
  --primary-top:50px;
  /* 056E00 FCFDF6  E6FFE6*/
}
.MuiButtonBase-root{
  font-family: Noto Sans HK!important;
}

.flex-center(@gap:0px){
  display:flex;
  gap:@gap;
  align-items: center;
  justify-content: center;
}

@media (prefers-color-scheme: dark) {
  body {
    --primary-color:#999 ;
    --primary-background:#FFEDE7;
    --primary-border:#D8C2BC;
    --primary-card-backgroun:#FDEEE7;
  }
}