.new-user{
  .MuiLinearProgress-root{
    height:8px;
  }
  .MuiLinearProgress-colorPrimary{
    background-color:transparent;
  }
  .new-user_main{
    margin-top:64px;
    padding:0 42px;
    .user-main_avatar{
      margin-bottom:100px;
      text-align: center;
      #icon-button-file{
        display:none;
      }
      .avatar-box{
        margin:0 auto;
        width:247px;
        height:247px;
        border:1px solid var(--primary-border);
        border-radius: 50%;
        display:flex;
        align-items: center;
        justify-content: center;
        overflow:hidden;
        img{
          width:100%;
          height:100%;
          object-fit:cover;
        }
        .avatar-box_text{
          font-size: 16px;
          color:var(--primary-color);
        }
      }
    }
    .user-main_message{
      display:flex;
      flex-wrap: wrap;
      gap:20px;
      .mian-message_title{
        color:#000;
        font-size: 18px;
        letter-spacing: 0.5px;
      }
      .MuiFormControl-root{
        width:100%;
        .MuiFormLabel-root{
          background-color:#fff;
          padding:0 5px;
        }
      }
    }
  }
  .new-user_buttom{
    margin-top:30px;
    padding:18px;
    display:flex;
    gap:16px;
    .MuiButtonBase-root{
      width:100%;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.1px;
      border-radius: 8px;
    }
  }
}
#simple-menu{
  .MuiPaper-root{
    width:163px;
    .MuiList-padding{
      padding:0;
      font-size:14px;
      .MuiButtonBase-root{
        font-size:14px;
        display:flex;
        justify-content: space-between;
        label{
          width:100%;
          display:flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .browse-file{
        justify-content: center;
        background:var(--primary-color);
        color:#FFF;
        font-weight: 700;
        letter-spacing: -0.5px;
      }
    }
    
  }
}