.payment{
  .payment-main{
    padding:22px;
    .payment-main_list{
      margin-top:33px;
      display:flex;
      gap:15px;
      flex-wrap: wrap;
      flex-direction: column;
      .MuiFormControl-root{
        width:100%;
        margin:0;
        .MuiFormGroup-root{
          display:flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap:15px;
          
        }
        
      }
      .main-list_card{
        height:72px;
        display: flex;
        gap:24px;
        align-items: center;
        padding: 8px 24px 8px 16px;
        box-sizing: border-box;
        background-color:#fff3f7;
        .main-list_card-info{
          .main-list_card-info-name{
            color:#53433E;
            font-size:14px;
            font-weight: 700;
            letter-spacing: 0.5px;
          }
          .main-list_card-info-des{
            margin-top:5px;
            color:#201A18;
            font-size:15px;
            font-weight: 300;
            letter-spacing: 0.5px;
          }
        }
        .MuiFormControlLabel-root,.main-list_card-btn{
          margin-left: auto;
          margin-right:0;
        }
        .main-list_card-btn{
          display:flex;
          align-items: center;
          gap:15px;
          >div{
            text-align: center;
            span{
              margin-top:5px;
              display:block;
              font-size:12px;
              letter-spacing: 0.5px;
            }
            svg{
              path{
                stroke:var(--primary-color);
              }
            }
            .icon-code{
              path{
                stroke: none;
                fill:var(--primary-color)
              }
            }
          }
        }
      }
    }
  }
}