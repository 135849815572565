.food-dialog{
  .MuiPaper-root{
    width:298px;
    height:329px;
    padding: 39px 42px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    .append-dialog_img{
      width:137px;
      height:137px;
      margin:0 auto;
    }
    .append-dialog_input{
      display:flex;
      margin-bottom:8px;
      gap:8px;
    }
    .append-dialog_button{
      display:flex;
      padding:0 20px;
      box-sizing: border-box;
      .MuiButtonBase-root{
        width:100%;
        height:40px;
        border-radius: 8px;
        .MuiButton-label{
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.1px;
          img{
            height:28px;
            width:28px;
          }
        }
      }
    }
  }
}
