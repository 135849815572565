.forgot{
  .forgot-logo{
    padding:47px 36px;
    text-align: center;
  }
  .forgot-form{
    padding:26px 59px;
    form{
      display:flex;
      flex-wrap: wrap;
      gap:24px;
      .MuiFormControl-root{
        width:100%;
      }
      .forgot-form_checkbox{
        width:100%;
        display:flex;
        justify-content: space-between;
        align-items: center;
        .MuiFormControlLabel-root{
          .PrivateSwitchBase-root-5{
            padding:0 9px;
          }
          .MuiTypography-body1{
            font-size: 12px;
            letter-spacing: 0.5px;
          }
        }
        .forgot-form_checkbox-forgot{
          color:#F0560F;
          font-size: 12px;
          letter-spacing: 0.5px;
        }
      }
      .MuiButton-containedPrimary{
        width:100%;
        height:40px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.1px;
      }
      .forgot-form_login{
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: var(--WC-sys-light-on-surface-variant, #53433E);
        letter-spacing: 0.4px;
      }
    }
  }
}