.dialog-contact{
  .MuiPaper-root{
    width:328px;
    height:565px;
    padding: 64px 16px 78.279px 16px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #FFF;
   
    .dialog-contact_logo{
      display:flex;
      width:102px;
      height:102px;
      margin:0 auto;
    }
    .dialog-contact_title{
      margin:18px 0;
      text-align: center;
      color: var(--WC-sys-light-on-primary-container, #370E00);
      font-size: 22px;
    }
    .dialog-contact_list{
      display:flex;
      flex-wrap: wrap;
      gap:10px;
      .list-item{
        width:100%;
        display:flex;
        align-items: center;
        border-radius: 16px;
        padding:16px;
        background: var(--primary-background, #FFEDE7);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        .list-item_icon{
          margin-right:16px;
        }
        .list-item_info{
          font-size: 18px;
          color: var(--WC-sys-light-on-primary-container, #370E00);
          letter-spacing: 0.1px;
          .info-email{
            font-size: 14px;
            letter-spacing: 0.4px;
          }
        }
      }
    }
  }
}