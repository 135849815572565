.exchange{
  background:#edeaf3;
  min-height:100vh;
  padding:30px;
  .exchange-card{
    padding:20px;
    border-radius: 20px;
    background-color: #fff;
    border-radius: 1px solid #e1e5e8;
    .card-main{
      .card-main_head{
        display:flex;
        justify-content: space-between;
        align-items: center;
        .main-head_title{
          color:#1b074c;
          font-weight: 600;
          font-size:16px;
        }
        .main-head_balance{
          color:#707aac;
          font-size:14px;
        }
      }
      .card-main_input{
        margin-top:20px;
        width:100%;
        min-height:100px;
        border-radius: 20px;
        border: 1px solid #d2d8dd;
        background:#edeaf3;
        .MuiTextField-root{
          width:100%;
          height:100%;
          outline:none;
          .MuiInputBase-root{
            border: 0;
            outline:none;
            .MuiInputBase-input{
              text-align: right;
            }
            .MuiOutlinedInput-notchedOutline{
              border:0;
              outline:none;
            }
          }
          
        }
      }
    }
    .card-toggle{
      width:40px;
      height:40px;
      border-radius: 50%;
      background:#ede9f4;
      padding:8px;
      box-sizing: border-box;
      margin:30px auto;
      cursor: pointer;
      box-shadow: 0px 2px 2px #b5b5bb;
    }
    .MuiButton-root{
      margin-top:30px;
      width:100%;
      border-radius: 15px;
      height:45px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}