.MuiBottomNavigation-root{
  width:100%;
  height:81px!important;
  position:fixed;
  bottom:0px;
  background-color:var(--primary-background,#fff)!important;
  z-index: 999;
  .MuiBottomNavigationAction-label{
    margin-top:8px;
    font-size:12px!important;
  }
  .Mui-selected{
    font-weight: 600;
  }
}
.navigation-height{
  height:81px;
}
.navigation-drawer{
  .MuiPaper-root{
    background:transparent;
    .navigation-tab{
      padding:0 0 90px 20px;
      display:flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap:10px;
      .navigation-tab_item{
        width:68px;
        height:68px;
        background-color:var(--primary-background);
        border-radius: 9px;
        display:flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap:6px;
        span{
          font-size:12px;
          color:#311300;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}
.navigation-home_tab{
  bottom:0;
  position:fixed;
  z-index: 998;
  height:100%;
  .navigation-tab-screen{
    position:absolute;
    top:0;
    bottom:0;
    width:100vw;
    height:100vh;
    background-color:rgba(0,0,0,0.5);
  }
  .navigation-tab{
    position:absolute;
    bottom:0px;
    left:20px;
    .navigation-tab_item{
      width:68px;
      height:68px;
      background-color:var(--primary-background);
      border-radius: 9px;
      display:flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap:6px;
      transition: .5s;
      position:absolute;
      bottom:0;
      span{
        font-size:12px;
        color:#311300;
        letter-spacing: 0.5px;
      }
    }
    .navigation-tab_item-active{
      background-color:var(--primary-color);
      span{
        color:#fff;
      }
      svg{
        path{
          fill:#fff;
        }
      }
    }
  }
  .navigation-tab_active{
    .navigation-tab_item:nth-child(1){
      bottom:170px;
    }
    .navigation-tab_item:nth-child(2){
      bottom:90px;
    }
  }
}