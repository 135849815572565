.shear-dialog{
  .MuiPaper-root{
    width:288px;
    height:auto;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FFF;
    .dialog-main_title{
      margin-top:16px;
      color:#000;
      font-size: 18px;
      letter-spacing: 0.018px;
    }
    .dialog-main_list{
      margin-top:22px;
      display:flex;
      flex-wrap: wrap;
      gap:16px;
      .list-item{
        width:100%;
        padding:8px 24px;
        border-radius: 8px;
        background-color:var(--primary-background);
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        display:flex;
        align-items: center;
        gap:10px;
        color:#000;
        .list-item_info-title{
          font-size: 16px;
          letter-spacing: 0.1px;
        }
        .list-item_info-text{
          margin-top:5px;
          font-size: 14px;
          letter-spacing: 0.056px;
        }
      }
    }
  }
}