.transition{
  .transition-main{
    margin-top:20px;
    .MuiTabs-root{
      padding:10px;
      width:100%;
      box-sizing: border-box;
      .MuiTabs-flexContainer{
        border-bottom:1px solid #6A6A6A;
        .MuiButtonBase-root {
          min-height:30px;
          width:50%;
          color:var(--primary-color);
          font-size:18px;
          letter-spacing: 0.1px;
          padding:5px;
        }
      }
      .MuiTabs-indicator{
        height:2px;
      }
    }
    
    .transition-main_list{
      padding:16px;
      display:flex;
      flex-wrap: wrap;
      gap:8px;
      .list-item{
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
        padding:8px 24px 8px 16px;
        background-color:var(--primary-background);
        box-sizing: border-box;
        .list-item_left{
          width:70%;
          .left-date{
            color:#201A18;
            font-size:18px;
            letter-spacing: 0.5px;
          }
          .left-text{
            margin-top:5px;
            font-size: 16px;
            letter-spacing: 0.25px;
            overflow: hidden;
            -webkit-line-clamp: 1; 
            -webkit-box-orient: vertical;
            display: -webkit-box;
            word-break:break-all
          }
        }
        .list-item_right{
          color:#53433E;
          font-size:12px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}