.screen-dialog{
  .MuiPaper-root{
    height:90vh;
    width:100%;
    background-color:#fff;
    border-radius: 25px 25px 0px 0px;
    .screen-main{
      padding:15px;
      padding-bottom:0;
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      flex-direction: column;
      .screen-main_top{
        width:130px;
        height:9px;
        border-radius: 12px;
        background:var(--primary-background);
        margin:0 auto;
      }
      .content{
        .title{
          font-size:18px;
          margin:28px 0;
        }
        .select{
          display:flex;
          gap:10px;
          // justify-content: space-between;
          justify-content: flex-start;
          flex-wrap: wrap;
          .select-item{
            height:40px;
            box-sizing: border-box;
            font-size:15px;
            padding:10px 15px;
            border-radius: 50px;
            background-color:var(--primary-background);
            display:flex;
            align-items: center;
          }
          .select-card{
            max-width:105px;
            min-width:96px;
            height:108px;
            box-sizing: border-box;
            justify-content:center;
            align-items: center;
            display:flex;
            flex-wrap: wrap;
            background-color:var(--primary-background);
            border-radius: 8px;
            svg{
              height:56px;
            }
            span{
              text-align: center;
              width:100%;
              display:block;
            }
          }   
          .active{
            background-color:var(--primary-color);
            color:#fff;
            svg{
              path{
                fill:#fff;
              }
            }
          }
        }
      }
      .button{
        display:flex;
        position: sticky;
        bottom:0px;
        background-color:#fff;
        padding:10px 10px 20px 10px ;
        gap:16px;
        .MuiButtonBase-root{
          width:100%;
          border-radius: 8px;
        }
      }
    }
  }
}