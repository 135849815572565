.shear{
  padding:16px;
  padding-top:var(--primary-top);
  background-color:#F4F4F4;
  min-height:100vh;
  .shear-title{
    color:#370E00;
    font-size: 22px;
    font-weight: 500;
  }
  .MuiTabs-root{
    margin-top:8px;
    .MuiTabs-scroller{
      .MuiTabs-flexContainer{
        justify-content: space-between;
        .MuiButtonBase-root{
          // width:100%;
        }
      }
    }
  }
  .shear-box{
    margin-top:8px;
    height:156px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color:var(--primary-background);
    .shear-box_add{
      color:#370E00;
      font-size: 14px;
      font-weight: 300;
      display:flex;
      align-items: center;
      gap:8px;
    }
  }
  .shear-btn{
    margin-top:16px;
    .MuiButtonBase-root{
      width:100%;
      height:40px;
      border-radius: 8px;
      font-size:16px;
      font-weight: 600;
      letter-spacing: 0.1px;
    }
  }
}