.append-dialog{
  z-index: 9999!important;
  .MuiPaper-root{
    width:298px;
    height:310px;
    padding: 29px 71px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap:12px;
    .append-dialog_img{
      width:156px;
      height:144px;
    }
    .append-dialog_container{
      display:flex;
      align-items: center;
      justify-content: space-between;
      .MuiButtonBase-root{
        img{
          width:24px;
          height:24px;
        }
      }
      .container_amount{
        font-size:16px;
        color:#000;
        letter-spacing: 0.1px;
      }
    }
    .append-dialog_button{
      width:100%;
      height:40px;
      border-radius: 8px;
      .MuiButton-label{
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1px;
        img{
          height:28px;
          width:28px;
        }
      }
    }
  }
}
