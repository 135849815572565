.vegetable{
  min-height:100vh;
  padding:20px 14px;
  padding-top:var(--primary-top);
  background-color:#fff;
  .vegetable-head{
    display:flex;
    justify-content: space-between;
    .vegetable-head_left{
      display:flex;
      align-items: center;
      cursor: pointer;
      .head-left_text{
        margin-left:8px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.1px;
      }
    }
  }
  .vegetable-info{
    text-align: center;
    .MuiAvatar-root {
      width:70px;
      height:70px;
      margin:0 auto;
      // box-shadow: 1px 0 0 rgb(0,0,0);
    }
    .vegetable-info_name{
      margin-top:5px;
      color:#000;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.018px;
    }
  }
  .vegetable-main{
    margin-top:16px;
    display:flex;
    gap:16px;
    flex-wrap: wrap;
    color:#370E00;
    >div{
      width:100%;
    }
    .vegetable-main_title{
      font-size:24px;
      font-weight: 500;
      text-align: center;
    }
    .vegetable-main_date{
      display:flex;
      gap:11px;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.018px;
      .MuiDivider-flexItem{
        max-width:1px;
        width:1px;
        height:27px;
        background-color:#370E00;
      }
    }
    .vegetable-main_intro{
      margin-top:6px;
      color:#53433E;
      text-align: center;
      font-size:14px;
      font-weight: 300;
      letter-spacing: 0.5px;
      padding-bottom:10px;
      border-bottom:1px solid #73796E;
    }
    .vegetable-main_img{
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      img{
        width:100%;
        border-radius: 8px;
      }
    }
    .vegetable-main_list{
      display:flex;
      gap:16px;
      flex-wrap: wrap;
      .list-item{
        width:100%;
        height:49px;
        border-bottom:1px solid #73796E;
        display:flex;
        align-items: center;
        gap:8px;
        color:#53433E;
        .list-item_label{
          width:92px;
          font-size:12px;
          letter-spacing: 0.5px;
        }
        .list-item_value{
          font-size:14px;
          letter-spacing: 0.056px;
          font-weight: 300;
        }
      }
      .list-item:last-child{
        // border:0;
      }
    }
    .vegetable-main_button{
      .MuiButtonBase-root{
        width:100%;
        font-size:16px;
        letter-spacing: 0.1px;
        font-weight: 600;
        height:50px;
        border-radius: 8px;
      }
    }
  }
}