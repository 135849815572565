.MuiSnackbar-root{
  .MuiPaper-root{
    height: 50px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    display:flex;
    align-items: center;
    svg{
      path,circle{
        fill:var(--primary-color);
      }
    }
    
  }
}