.intro{
  .intro-main{
    padding:20px;
    display:flex;
    gap:20px;
    flex-wrap: wrap;
    flex-direction: column;
    .intro_card{
      display:flex;
      align-items: center;
      gap:30px;
      padding:15px 20px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      .intro_card-left{
        display:flex;
        gap:8px;
        flex-wrap: wrap;
        flex-direction: column;
        .intro_card-left-avatar{
          position:relative;
          .intro_card-left-avatar_edit{
            position:absolute;
            bottom:0;
            right:0;
            path{
              fill:var(--primary-color);
            }
            circle{
              fill:var(--primary-background);
            }
          }
        }
        .intro_card-left-name{
          display:flex;
          gap:8px;
          justify-content: center;
          align-items: center;
          font-size:24px;
          font-weight: 500;
          svg{
            path{
              fill:var(--primary-color);
            }
          }
        }
      }
      .intro_card-right{
        display:flex;
        gap:5px;
        flex-wrap: wrap;
        flex-direction: column;
        color:#370E00;
        .intro_card-right-standing{
          font-size:15px;
          font-weight: 700;
          letter-spacing: 0.3px;
          line-height:32px;
          .MuiDivider-root{
            border-color:#D8C2BB!important;
          }
        }
        .intro_card-right-info{
          .right-info_num{
            font-size:20px;
            font-weight: 500;
            line-height:32px;
          }
          .right-info_des{
            font-size:12px;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: normal;
          }
        }
      }
    }
    .intro_aptitude{
      display:flex;
      flex-wrap: wrap;
      flex-direction: column;
      gap:20px;
      .intro_aptitude-head{
        display:flex;
        align-items: center;
        justify-content: space-between;
        label{
          color: #370E00;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.5px;
        }
        svg{
          path{
            fill:var(--primary-color);
          }
          rect{
            fill:var(--primary-background);
          }
        }
      }
      .intro_aptitude-content{
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap:20px;
        .aptitude-content-item{
          position:relative;
          padding-left:35px;
          .aptitude-content-item_head{
            display:flex;
            justify-content: space-between;
            align-items: center;
            .item_head-info{
              .item_head-info-date{
                color:#370E00;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.5px;
              } 
              .item_head-info-des{
                color: #000;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0.072px;
              }
            }
            .item_head-status{
              
            }
          }
          .aptitude-content-item_main{
            margin-top:10px;
          }
        }
        .aptitude-content-item::after{
          content:'';
          height:100%;
          width:2.5px;
          background-color:var(--primary-color);
          position:absolute;
          top:0;
          left:15px;
        }
        .aptitude-content-item::before{
          content:'';
          height:100%;
          width:15px;
          height:15px;
          border-radius: 50%;
          background-color:var(--primary-color);
          position:absolute;
          top:0;
          left:9px;
        }
      }
    }
  }
}