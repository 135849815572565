.dialog-order{
  .MuiPaper-root{
    background-color:transparent;
    .dialog-order_await,.dialog-order_success,.dialog-order_error{
      width:298px;
      height:298px;
      background-color: #fff;
      border-radius: 16px;
      display:flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      font-size:24px;
      font-weight: 500;
      text-align: center;
    }
    .dialog-order_success{
      background-color:#E5FFEE;
    }
    .dialog-order_error{
      background-color: #FFC7C2;
    }
  }
}